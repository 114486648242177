@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  /* border: solid 1px black; */
  /* border-radius: var(--borderRadiusMedium); */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  /* margin-bottom: 4px; */
}


.mainInfo {
  display: flex;
  flex-direction: column;
}


.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.title {
  /* font-size: 26px; */
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.7px;
  /* font-weight: 500; */

  @media (--viewportMedium) {}
}

.discounted {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.priceValue {
  /* font-weight: 500; */
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  color: var(--colorBlack);
  letter-spacing: 1.4px;
}

.priceDiscounted {
  composes: priceValue;
  color: #FD0000;
}

.priceOriginal {
  composes: priceValue;
  color: #000000;
  opacity: 0.2;
  text-decoration: line-through;
}



.size {
  text-transform: uppercase;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  color: var(--colorBlack);
  opacity: 0.5;
  letter-spacing: 1.3px;
}