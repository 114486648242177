@import '../../../styles/customMediaQueries.css';

.labelPlain {
  font-size: 15px;
  font-weight: 400;
}

.labelPlainSelected {
  color: black;
  font-size: 15px;
  font-weight: 400;
}