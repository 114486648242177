@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;

  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    /* border-bottom: 0; */
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 44px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: h4 from global;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin: 0px;
  }
}

.label {
  color: var(--colorBlack);
  font-size: 15px;
}

.labelSelected {
  color: var(--marketplaceColor);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.plain {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}