@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 24px;
  /* margin-right: 40px; */
  padding-bottom: 17px;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    /* border-bottom: 0; */
  }
}

.filterHeader {
  line-height: 44px;
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: h4 from global;
  text-transform: uppercase;
  font-weight: 400;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
  font-size: 15px;

  @media (--viewportMedium) {
    padding: 0;
    margin: 0;
  }
}

.label {
  color: var(--colorBlack);
}

.labelSelected {
  color: var(--marketplaceColor);
  white-space: nowrap;
}

.labelButton {
  /* Override button styles */
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
  line-height: 44px;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.openSign {
  float: right;
}

.formWrapper {
  /* padding-left: 12px; */
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}