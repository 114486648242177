.root {
  padding-top: 16px;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
}

.filterLabel,
.filterLabelSelected {
  composes: h4 from global;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;

  /* Baseline adjustment for label text */
  margin: 0;
  /* padding: 0 0 8px 0; */
}

.filterLabel {
  color: var(--colorBlack);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.optionsContainerOpen {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  align-items: flex-start;
  /* padding-bottom: 30px; */
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBorder {
  width: 0;
  background-color: var(--colorBlack);
}

/* left static border for selected element */
.optionBorderSelected {
  width: 8px;
  background-color: var(--colorBlack);
}

.option {
  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);
  text-align: left;
  font-size: 18px;
  line-height: 36px;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--colorGrey300);
    cursor: default;
  }
}

.optionSelected {
  composes: option;
  color: var(--marketplaceColor);
}

.labelButtonContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  line-height: 44px;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}