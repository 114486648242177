.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
}

.searchResultSummary {
  composes: textSmall from global;

  /* margin-top: 10px; */
  /* margin-bottom: 11px; */
  /* margin-left: 0px; */
  /* margin-right: 8px; */
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  font-size: 24px;
  color: var(--colorBlack);
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.breadcrumbsTitle {
  composes: h2 from global;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 48px;
  color: var(--colorBlack);
  margin: 0;
  padding: 0;
}